import { Component, OnInit, Inject } from '@angular/core';
import { faChevronDown, faDesktop, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import { faClock, faLaughBeam } from '@fortawesome/free-regular-svg-icons';
import { faFacebookF, faInstagram, faLinkedinIn, faTwitter, faGithub } from '@fortawesome/free-brands-svg-icons';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';
import { UtilsService } from 'src/app/services/utils.service';
import { ApiService } from 'src/app/services/api.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public formMail: FormGroup;
  public faChevronDown = faChevronDown;
  public faDesktop = faDesktop;
  public faMobileAlt = faMobileAlt;
  public faClock = faClock;
  public faLaughBeam = faLaughBeam;
  public faFacebookF = faFacebookF;
  public faInstagram = faInstagram;
  public faLinkedinIn = faLinkedinIn;
  public faTwitter = faTwitter;
  public faGithub = faGithub;
  public jobs = [];
  public contacts = [];
  public sent = false;
  public isLoading = false;

  constructor(
    private pageScrollService: PageScrollService,
    @Inject(DOCUMENT) private document: any,
    public util: UtilsService,
    private api: ApiService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.listJobs();
    this.prepareForm();
    this.listContacts();
  }

  public scrollDown(event: PointerEvent, id: string) {
    event.preventDefault();
    this.pageScrollService.scroll({
      document: this.document,
      scrollTarget: id,
      duration: 1250,
      easingLogic: this.util.easingLogic
    });
  }

  private listJobs() {
    this.api.get('jobs.json')
      .subscribe((data: any) => {
        this.jobs = data.reverse();
      });
  }

  private listContacts() {
    this.api.get('contacts.json')
      .subscribe((data: any) => {
        this.contacts = data;
      });
  }

  private prepareForm() {
    this.formMail = this.fb.group({
      nome: this.fb.control('', [Validators.required]),
      email: this.fb.control('', [Validators.required]),
      telefone: this.fb.control(''),
      mensagem: this.fb.control('', [Validators.required])
    });
  }

  public wowDelay = (seconds: number, index: number, interval: number) => `${seconds}.${index+interval}s`;

  public formError = (control: string) => this.formMail.get(control).errors && this.sent ? true : false;

  public send() {
    const requiredFields = [];
    const phoneFields = [];
    this.sent = true;
    if (!this.formMail.valid) {
      Object.keys(this.formMail.controls).forEach(control => {
        if (this.formMail.get(control).errors?.required) {
          requiredFields.push(this.formMail.get(control).errors)
        }

        if (this.formMail.get(control).errors?.mask) {
          phoneFields.push(this.formMail.get(control).errors)
        }
      });
      if (requiredFields.length) {
        this.toastr.error('Preencha todos os campos obrigatórios.');
      }
      if (phoneFields.length) {
        this.toastr.error('Preencha os campos corretamente.');
      }
    } else {
      this.isLoading = true;
      this.api.post('send-mail', this.formMail.value)
        .subscribe((data: any) => {
          this.isLoading = false;
          if (data.status) {
            this.toastr.success(data.msg, 'Sucesso!');
            this.formMail.reset();
            this.sent = false;
          } else {
            this.toastr.error(data.msg, 'Erro');
          }
        }, (err: any) => {
          this.isLoading = false;
          this.toastr.error(err.msg, 'Erro');
        });
    }
  }

}

<!-- Entrance -->
<section id="home" class="entrance" parallax [config]="{ratio: .3}">
    <div class="overlay">
        <h2 class="text-center wow fadeIn">
            Olá, me chamo <span class="name">Bernardo Sampaio</span><br>
            Sou Front-End Developer <span class="cartoon">:)</span>
        </h2>
    </div>

    <a href="" class="scroll-down" (click)="scrollDown($event, '#about')">
        <fa-icon [icon]="faChevronDown"></fa-icon>
    </a>
</section>

<!-- About -->
<section id="about" class="about">
    <div class="container">
        <div class="row">
            <div class="col-12 mb-50">
                <div class="info">
                    <div class="picture wow fadeInDown" data-wow-delay="0.5s">
                        <div class="box-img">
                            <img src="../../../assets/img/me.jpg" alt="Bernardo Sampaio">
                        </div>
                    </div>
                    <div class="summary">
                        <h3 class="text-center mt-15 wow fadeInDown" data-wow-delay="0.6s">Quem sou eu?</h3>
                        <p class="text-center mt-15 wow fadeInDown" data-wow-delay="0.7s">
                            Sou desenvolvedor Front-End na <a href="https://ilegra.com/" target="_blank" class="custom-link">ilegra</a> em Porto Alegre.<br>
                            Procuro sempre trazer a melhor experiência de interface para o usuário.<br>
                            Chega mais, vem conferir o meu trabalho.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="skills">
                    <ul class="list-unstyled list-skills">
                        <li>
                            <div class="skill">
                                <div class="skill-bubble mb-15 wow fadeInLeft" data-wow-delay="1s">
                                    <fa-icon [icon]="faDesktop"></fa-icon>
                                </div>
                                <h4 class="wow fadeInLeft" data-wow-delay="1.1s">Design limpo</h4>
                            </div>
                        </li>
                        <li>
                            <div class="skill">
                                <div class="skill-bubble mb-15 wow fadeInLeft" data-wow-delay="1.2s">
                                    <fa-icon [icon]="faClock"></fa-icon>
                                </div>
                                <h4 class="wow fadeInLeft" data-wow-delay="1.3s">Prazo</h4>
                            </div>
                        </li>
                        <li>
                            <div class="skill">
                                <div class="skill-bubble mb-15 wow fadeInLeft" data-wow-delay="1.4s">
                                    <fa-icon [icon]="faMobileAlt"></fa-icon>
                                </div>
                                <h4 class="wow fadeInLeft" data-wow-delay="1.5s">Responsivo</h4>
                            </div>
                        </li>
                        <li>
                            <div class="skill">
                                <div class="skill-bubble mb-15 wow fadeInLeft" data-wow-delay="1.6s">
                                    <fa-icon [icon]="faLaughBeam"></fa-icon>
                                </div>
                                <h4 class="wow fadeInLeft" data-wow-delay="1.7s">Cliente satisfeito</h4>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Jobs -->
<section id="jobs" class="jobs">
    <div class="container">
        <h1 class="title-bar wow fadeInDown" data-wow-delay="0.5s">Meus trabalhos</h1>
        <div class="jobs-container mt-50">
            <div 
                class="job-box wow fadeInLeft" 
                [attr.data-wow-delay]="wowDelay(0, i, 1)" 
                *ngFor="let job of jobs; let i = index"
                [ngClass]="{
                    'flex-1': jobs.length <= 1, 
                    'flex-2': jobs.length === 2 || jobs.length === 4, 
                    'flex-3': jobs.length > 2 && jobs.length !== 4
                }">
                <a [href]="job.url" target="_blank">
                    <div class="img-box">
                        <img [src]="job.img" [alt]="job.title">
                        <div class="img-overlay">
                            <p>{{job.title}}</p>
                            <ul class="list-unstyled list-techs">
                                <li *ngFor="let tech of job.technologies">
                                    <fa-icon [icon]="util.getIcon(tech.tech)"></fa-icon>
                                </li>
                            </ul>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>

<!-- Contact -->
<section id="contact" class="contact">
    <div class="container">
        <h1 class="title-bar wow fadeInDown mb-75" data-wow-delay="0.5s">Contato</h1>

        <div class="row">
            <div class="col-12 col-md-5 mb-xs-50">
                <h3 class="wow fadeInDown" data-wow-delay="0.6s">Me mande um Email 👻</h3>
                <form [formGroup]="formMail">
                    <div class="row">
                        <div class="col-12 mb-15 wow fadeInLeft" data-wow-delay="0.7s">
                            <input 
                                type="text" 
                                class="form-control" 
                                [class.is-invalid]="formError('nome')" 
                                formControlName="nome" 
                                placeholder="Nome*" 
                                required />
                        </div>
                        <div class="col-12 mb-15 wow fadeInLeft" data-wow-delay="0.8s">
                            <input 
                                type="email" 
                                class="form-control" 
                                [class.is-invalid]="formError('email')" 
                                formControlName="email" 
                                placeholder="Email*" 
                                required />
                        </div>
                        <div class="col-12 mb-15 wow fadeInLeft" data-wow-delay="0.9s">
                            <input 
                                type="text" 
                                class="form-control" 
                                [class.is-invalid]="formError('telefone')" 
                                formControlName="telefone" 
                                placeholder="Telefone" 
                                mask="(00) 00000-0000" 
                                [dropSpecialCharacters]="false" />
                        </div>
                        <div class="col-12 mb-15 wow fadeInLeft" data-wow-delay="1s">
                            <textarea 
                                class="form-control" 
                                rows="6" 
                                [class.is-invalid]="formError('mensagem')" 
                                formControlName="mensagem" 
                                placeholder="Mensagem*" 
                                required>
                            </textarea>
                        </div>
                        <div class="col-12 wow fadeInRight" data-wow-delay="1.1s">
                            <button 
                                type="submit" 
                                class="btn btn-send btn-block-xs float-right" 
                                (click)="send()" 
                                [disabled]="isLoading">
                                <span *ngIf="!isLoading">Enviar</span>
                                <span *ngIf="isLoading" class="spinner-border spinner-border-sm"></span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>

            <div class="col-12 col-md-5 offset-md-2">
                <h3 class="wow fadeInUp" data-wow-delay="1.3s">🤨 Ou se preferir...</h3>
                <ul class="list-unstyled list-networks">
                    <li 
                        *ngFor="let contact of contacts; let i = index" 
                        class="wow fadeInRight" 
                        [attr.data-wow-delay]="wowDelay(1, i, 3)">
                        <fa-icon [icon]="util.getIcon(contact.network)"></fa-icon>
                        <a [href]="contact.url" target="_blank" [ngClass]="util.lowerCase(contact.network)">
                            <span>{{contact.network}}</span>
                        </a>
                    </li>
                </ul>
                <div class="title-line mt-50">
                    <h3 class="title wow fadeInUp" data-wow-delay="2s">Segue lá</h3>
                    <div class="line wow fadeIn" data-wow-delay="2.1s"></div>
                </div>
                <ul class="list-unstyled list-social mt-15">
                    <li class="social-facebook wow fadeInLeft" data-wow-delay="2.7s">
                        <a href="https://facebook.com/bernardo.sampaio.3" target="_blank">
                            <fa-icon [icon]="faFacebookF"></fa-icon>
                        </a>
                    </li>
                    <li class="social-instagram wow fadeInLeft" data-wow-delay="2.6s">
                        <a href="https://instagram.com/brdsampaio" target="_blank">
                            <fa-icon [icon]="faInstagram"></fa-icon>
                        </a>
                    </li>
                    <li class="social-linkedin wow fadeInLeft" data-wow-delay="2.5s">
                        <a href="https://linkedin.com/in/bernardo-sampaio-596862140/" target="_blank">
                            <fa-icon [icon]="faLinkedinIn"></fa-icon>
                        </a>
                    </li>
                    <li class="social-twitter wow fadeInLeft" data-wow-delay="2.4s">
                        <a href="https://twitter.com/brdppl" target="_blank">
                            <fa-icon [icon]="faTwitter"></fa-icon>
                        </a>
                    </li>
                    <li class="social-github wow fadeInLeft" data-wow-delay="2.3s">
                        <a href="https://github.com/brdppl" target="_blank">
                            <fa-icon [icon]="faGithub"></fa-icon>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>

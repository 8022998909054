import { Injectable } from '@angular/core';
import { EasingLogic } from 'ngx-page-scroll-core';
import {
  faHtml5,
  faAngular,
  faSass,
  faGulp,
  faNodeJs,
  faWhatsapp,
  faTelegram,
  faSkype,
  faFacebookMessenger,
  faReact,
  IconDefinition
} from '@fortawesome/free-brands-svg-icons';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  public easingLogic: EasingLogic = (t: number, b: number, c: number, d: number): number => {
    // easeInOutExpo easing
    if (t === 0) return b;
    if (t === d) return b + c;
    if ((t /= d / 2) < 1) return c / 2 * Math.pow(2, 10 * (t - 1)) + b;
    return c / 2 * (-Math.pow(2, -10 * --t) + 2) + b;
  }

  public getIcon(value: string): IconDefinition {
    let icon: IconDefinition;
    switch(value.toLowerCase()) {
      case 'html':
      case 'html5':
        icon = faHtml5;
        break;
      case 'angular':
      case 'angularjs':
        icon = faAngular;
        break;
      case 'react':
      case 'reactjs':
        icon = faReact;
        break;
      case 'sass':
      case 'scss':
        icon = faSass;
        break;
      case 'gulp':
      case 'gulp.js':
        icon = faGulp;
        break;
      case 'node':
      case 'nodejs':
      case 'node.js':
        icon = faNodeJs;
        break;
      case 'whats':
      case 'whatsapp':
        icon = faWhatsapp;
        break;
      case 'skype':
        icon = faSkype;
        break;
      case 'telegram':
        icon = faTelegram;
        break;
      case 'messenger':
        icon = faFacebookMessenger;
        break;
    }
    return icon;
  }

  public lowerCase = (value: string): string => value.toLowerCase();
}

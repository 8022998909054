import { Component, OnInit, Inject } from '@angular/core';
import * as $ from 'jquery';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    private pageScrollService: PageScrollService,
    @Inject(DOCUMENT) private document: any,
    private util: UtilsService
  ) {
    $(document).on("scroll", function(){
      const page = $(document).scrollTop()
      const home = $('#home').offset().top - 170
      const about = $('#about').offset().top - 170
      const jobs = $('#jobs').offset().top - 170
      const contact = $('#contact').offset().top - 170

      if(page >= (about - home)/2){
        $('.header').addClass('show')
      } else {
        $('.header').removeClass('show')
      }

      // ScrollSpy logic
      page >= home && page < about ? $('#home-selector').addClass('active') : $('#home-selector').removeClass('active')
      page >= about && page < jobs ? $('#about-selector').addClass('active') : $('#about-selector').removeClass('active')
      page >= jobs && page < contact ? $('#jobs-selector').addClass('active') : $('#jobs-selector').removeClass('active')
      page >= contact ? $('#contact-selector').addClass('active') : $('#contact-selector').removeClass('active')
    })

    // Progress bar
    window.onscroll = () => {this.progress()}
  }

  ngOnInit(): void { }

  public goToSection(event, id) {
    event.preventDefault()
    // $('html, body').animate({scrollTop: $(id).offset().top }, 1250, 'easeInOutExpo')
    this.pageScrollService.scroll({
      document: this.document,
      scrollTarget: id,
      duration: 1250,
      easingLogic: this.util.easingLogic
    })
  }

  private progress() {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight
    const scrolled = (winScroll / height) * 100
    document.getElementById("progressBar").style.width = scrolled + "%"
  }

}

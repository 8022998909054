import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private jsonUrl: string = 'assets/data';
  private apiUrl: string = 'https://brdppl.herokuapp.com';

  constructor(
    private http: HttpClient
  ) { }

  public get = (endpoint: string) => this.http.get(`${this.jsonUrl}/${endpoint}`);
  public post = (endpoint: string, data: any) => this.http.post(`${this.apiUrl}/${endpoint}`, data);
}

<div class="header">
    <ul class="bubbles">
        <li class="bubble-item" id="home-selector">
            <a class="bubble-link" href="" (click)="goToSection($event, '#home')"></a>
        </li>
        <li class="bubble-item" id="about-selector">
            <a class="bubble-link" href="" (click)="goToSection($event, '#about')"></a>
        </li>
        <li class="bubble-item" id="jobs-selector">
            <a class="bubble-link" href="" (click)="goToSection($event, '#jobs')"></a>
        </li>
        <li class="bubble-item" id="contact-selector">
            <a class="bubble-link" href="" (click)="goToSection($event, '#contact')"></a>
        </li>
    </ul>
</div>

<div class="header-mobile">
    <div class="progress-bar" id="progressBar"></div>
</div>